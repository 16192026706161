import { Route, Routes } from 'react-router-dom';
import './App.css';
import whatsapp from'./whatsapp.png'
import Home from './container/Home';
import Course from './container/Course';
import Project from './container/Project';
import Placement from './container/Placement';
import Contact from './container/Contact';
import About from './container/About';
import Header from './container/Header/Header';
import Footer from './container/Footer/Footer';
import Pagenotfound from './container/containerpart/404Pagenotfound/Pagenotfound';
import Register from './container/containerpart/registration form/Registers';
import News from './container/containerpart/News/News';
import Gallery from './container/containerpart/Gallery/Gallery';
import topimg from './chevron-direction-top-round-outline-icon.png';

function App() {

  const top = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className="App">
    <Header/>
      <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='course' element={<Course/>}/>
      <Route path='about' element={<About/>}/>
      <Route path='project' element={<Project/>}/>
      <Route path='placement' element={<Placement/>}/>
      <Route path='contact' element={<Contact/>}/>
      <Route path='registration' element={<Register/>}/>
      <Route path='news' element={<News/>}/>
      <Route path='gallery' element={<Gallery/>}/>
      <Route path='*' element={<Pagenotfound/>}/>
     

      </Routes>
      <img src={topimg} alt=""  className='float1' onClick={top} />
      <a href="https://api.whatsapp.com/send?phone=+91 9513800500 &amp;text=Hello! EpitomeCircuits"> <img src={whatsapp} className="float" target="_blank"/>
            <i class="fa fa-whatsapp my-float"/>  
            </a>
      <Footer/>
      
   
    </div>
  );
}

export default App;
